
const fondos = [
    'fondos/fondo-6.jpg',
    'fondos/fondo-7.jpg',
    'fondos/fondo-8.jpg',
    'fondos/fondo-9.jpg',
    'fondos/fondo-10.jpg',
    'fondos/fondo-11.jpg',
    'fondos/fondo-12.jpg',
    'fondos/fondo-13.jpg',
    'fondos/fondo-14.jpg',
    'fondos/fondo-15.jpg',
    'fondos/fondo-16.jpg',
    'fondos/fondo-17.jpg',
    'fondos/fondo-18.jpg',
    'fondos/fondo-19.jpg',
    'fondos/fondo-20.jpg',
    'fondos/fondo-21.jpg',
    'fondos/fondo 1.jpg',
    'fondos/fondo 2.jpg',
    'fondos/fondo 3.jpg',
    'fondos/fondo 5.jpg',
    'fondos/fondo 6.jpg',
];

export default fondos

