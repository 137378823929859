
const accs = [
    'bmichi chup 1.png',
    'bmichi chup 2.png',
    'bmichi chup 3.png',
    'bmichi chup 4.png',
    'bmichi chup 5.png',
    'bmichi chup 6.png'
];

export default accs

