import "./CharacterEditor.sass";
import fondos from "../fondos.js"
import { useState } from "react";
import accs from "../accs.js";
import panales from "../panales.js";
import html2canvas from "html2canvas";



export default function CharacterEditor() {

    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const [actualBg, setActualBg] = useState(randomIntFromInterval(0, fondos.length - 1))

    const [actualFg, setActualFg] = useState(randomIntFromInterval(0, accs.length - 1))
    const [actualPl, setActualPl] = useState(randomIntFromInterval(0, panales.length - 1))

    const changeBg = () => {
        setActualBg(fondos.length - 1 === actualBg ? 0 : actualBg + 1)
    }

    const changeFg = () => {
        setActualFg(accs.length - 1 === actualFg ? 0 : actualFg + 1)
    }
    const changePl = () => {
        setActualPl(panales.length - 1 === actualPl ? 0 : actualPl + 1)
    }

    const saveImg = () => {
        html2canvas(document.querySelector("#capture")).then(canvas => {
            var resizedCanvas = document.createElement("canvas");
            var resizedContext = resizedCanvas.getContext("2d");

            resizedCanvas.height = "500";
            resizedCanvas.width = "500";

            resizedContext.drawImage(canvas, 0, 0, 500, 500);
            var myResizedData = resizedCanvas.toDataURL();
            const a = document.createElement("a");
            a.href = myResizedData;
            a.download = "BMICHI.png";
            a.click();
        });
    }

    return (
        <div className="containerEditor">
            <p>Click on the image to edit your BMICHI!</p>
            <div className="charEditor">
                <div id="capture" width="100%" height="100%">
                    <div className="editorBg"><img src={"/assets/" + fondos[actualBg]} alt="" /></div>
                    <div className="editorMain"><img src="/assets/baby.png" alt="" /></div>
                    <div className="editorFg"><img src={"/assets/panales/"+panales[actualPl]} alt="" /></div>
                    <div className="editorFg"><img src={"/assets/accs/" + accs[actualFg]} alt="" /></div>
                    <div className="editorButtons">
                        <div onClick={changeBg} className="bgButton"></div>
                        <div onClick={changeFg} className="accButton"></div>
                        <div onClick={changePl} className="plButton"></div>
                    </div>
                </div>
            </div>
            <button onClick={saveImg}>Save my BMICHI!</button>
        </div>
    )
}