
const panales = [
    'p-1.png',
    'p-2.png',
    'p-3.png',
    'p-4.png',
    'p-5.png',
];

export default panales

